import React, {ReactElement} from "react";

export const emptySearch = (color: string = '#B2C0C7'): ReactElement => {
  return (
    <svg viewBox="0 0 68 68">
        <g fill={color}>
          <path d="M65.8363636,55.9454545 L48.5272727,40.0272727 C47.2909091,38.9454545 45.4363636,38.9454545 44.2,40.1818182 L43.2727273,41.1090909 L40.6454545,38.4818182 C44.3545455,34.3090909 46.3636364,28.9 46.3636364,23.1818182 C46.3636364,10.3545455 36.0090909,0 23.1818182,0 C10.3545455,0 0,10.3545455 0,23.1818182 C0,36.0090909 10.3545455,46.3636364 23.1818182,46.3636364 C27.2,46.3636364 31.2181818,45.2818182 34.7727273,43.2727273 C35.5454545,42.8090909 35.7,41.8818182 35.3909091,41.1090909 C34.9272727,40.3363636 34,40.1818182 33.2272727,40.4909091 C30.1363636,42.1909091 26.7363636,43.1181818 23.1818182,43.1181818 C12.0545455,43.2727273 3.09090909,34.3090909 3.09090909,23.1818182 C3.09090909,12.0545455 12.0545455,3.09090909 23.1818182,3.09090909 C34.3090909,3.09090909 43.2727273,12.0545455 43.2727273,23.1818182 C43.2727273,28.4363636 41.2636364,33.5363636 37.5545455,37.2454545 C37.2454545,37.5545455 37.0909091,37.8636364 37.0909091,38.3272727 L37.0909091,38.6363636 C37.0909091,39.1 37.2454545,39.4090909 37.5545455,39.7181818 L41.1090909,43.2727273 L40.1818182,44.2 C38.9454545,45.4363636 38.9454545,47.2909091 40.0272727,48.5272727 L55.9454545,65.8363636 C57.1818182,67.2272727 59.0363636,68 61.0454545,68 C64.9090909,68 68,64.9090909 68,61.0454545 C68,59.1909091 67.2272727,57.3363636 65.8363636,55.9454545 Z M61.0454545,64.9090909 C59.9636364,64.9090909 58.8818182,64.4454545 58.2636364,63.6727273 L42.3454545,46.3636364 L46.3636364,42.3454545 L63.6727273,58.2636364 C64.4454545,59.0363636 64.9090909,59.9636364 64.9090909,61.0454545 C64.9090909,63.2090909 63.2090909,64.9090909 61.0454545,64.9090909 Z" fillRule="nonzero"/>
          <path d="M23,6 C13.5727273,6 6,13.5727273 6,23 C6,32.4272727 13.5727273,40 23,40 C32.4272727,40 40,32.4272727 40,23 C40,13.5727273 32.4272727,6 23,6 Z M23,36.9090909 C15.2727273,36.9090909 9.09090909,30.7272727 9.09090909,23 C9.09090909,15.2727273 15.2727273,9.09090909 23,9.09090909 C30.7272727,9.09090909 36.9090909,15.2727273 36.9090909,23 C36.9090909,30.7272727 30.7272727,36.9090909 23,36.9090909 Z" fillRule="nonzero"/>
          <path d="M23.4337349,12 C17.1686747,12 12,17.1686747 12,23.4337349 C12,24.373494 12.626506,25 13.5662651,25 C14.5060241,25 15.1325301,24.373494 15.1325301,23.4337349 C15.1325301,18.8915663 18.8915663,15.1325301 23.4337349,15.1325301 C24.373494,15.1325301 25,14.5060241 25,13.5662651 C25,12.626506 24.373494,12 23.4337349,12 Z"/>
        </g>
    </svg>
  );
};
