import React, {ReactElement} from "react";

export const magnify = (color: string = '#299BF6'): ReactElement => {
  return (
    <svg width="14px" height="14px" viewBox="0 0 14 14">
        <path d="M5.2,0 C8.072,0 10.4,2.328 10.4,5.2 C10.4,6.488 9.928,7.672 9.152,8.584 L9.368,8.8 L10,8.8 L14,12.8
        L12.8,14 L8.8,10 L8.8,9.368 L8.584,9.152 C7.672,9.928 6.488,10.4 5.2,10.4 C2.328,10.4 0,8.072 0,5.2 C0,2.328
        2.328,0 5.2,0 Z M5.2,1.6 C3.2,1.6 1.6,3.2 1.6,5.2 C1.6,7.2 3.2,8.8 5.2,8.8 C7.2,8.8 8.8,7.2 8.8,5.2 C8.8,3.2
        7.2,1.6 5.2,1.6 Z" fill={color}/>
    </svg>
  );
};
