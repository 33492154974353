const dropdownBodyStyles = {
    wrapper: {
      backgroundColor: '#ECF0F2',
      borderRadius: 4,
      boxShadow: '0 2px 5px 0 rgba(146,166,176,0.50)',
    },

    contentWrapper: {
      overflowX: 'hidden',
      overflowY: 'scroll',
      maxHeight: 'calc(100vh - 300px)',
      position: 'relative'
    },
};

export default dropdownBodyStyles;
